/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css } from "@emotion/react";
import Checkbox from "../Checkbox/Checkbox";
import PropTypes from "prop-types";

const CheckboxGroup = ({
  color,
  disabledColor,
  options,
  allEnabled,
  onCheckboxChange,
  onAllChanged,
}) => {
  const [checkedOptions, setCheckedOptions] = useState(new Map());
  const [disabledOptions, setDisabledOptions] = useState(new Map());

  const checkboxGroupStyle = css`
    display: flex;
    flex-direction: column;
  `;

  const onAllChange = (event) => {
    const checked = event.target.checked;
    const allOptions = new Map(checkedOptions);
    options.map(({ key }) => {
      allOptions.set(key, checked);
    });
    setCheckedOptions(allOptions);
    setDisabledOptions(allOptions);
    if (checked) {
      onAllChanged([...allOptions.keys()]);
    } else {
      onAllChanged([]);
    }
  };

  const onOptionChange = (key, event) => {
    const checked = event.target.checked;
    const allOptions = new Map(checkedOptions);
    allOptions.set(key, checked);
    setCheckedOptions(allOptions);
    onCheckboxChange(key, checked);
  };

  return (
    <div css={checkboxGroupStyle}>
      {allEnabled && (
        <Checkbox onChange={onAllChange} color={color}>
          All
        </Checkbox>
      )}
      {options.map(({ key, label }) => (
        <Checkbox
          key={key}
          checked={checkedOptions.get(key) || false}
          disabled={disabledOptions.get(key) || false}
          onChange={(event) => onOptionChange(key, event)}
          color={color}
          disabledColor={disabledColor}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  );
};

CheckboxGroup.defaultProps = {
  allEnabled: false,
  color: "default",
  disabledColor: "default",
};

CheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  allEnabled: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  onAllChanged: PropTypes.func,
  color: PropTypes.string,
  disabledColor: PropTypes.string,
};

export default CheckboxGroup;
