/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect, useContext } from "react";
import DrEventsContext from "../Reducers/DrEventsContext";
import Spinner from "../BasicComponents/Spinner/Spinner";
import DashboardService from "../Services/DashboardService";
import { ACTIONS } from "../Reducers/DrEventsReducer";
import { useAuth0 } from "@auth0/auth0-react";

const containerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 3px;
`;

const errorStyle = css`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 10px;
  align-items: center;
  color: white;
`;

const ConfigLoader = () => {
  const [context, dispatch] = useContext(DrEventsContext);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        return;
      }

      try {
        const dashboardService = new DashboardService(getAccessTokenSilently);
        const allTenants = await dashboardService.getTenants();
        dispatch({
          type: ACTIONS.LOADED_TENANTS,
          allConfiguredTenants: allTenants,
        });
      } catch (exception) {
        console.log(exception);
        setError("Failed to retrieve tenants!");
      }
    })();
  }, [isAuthenticated]);

  return (
    <div css={containerStyle}>
      {error && <div css={errorStyle}>Error: {error}</div>}
      {!error && context.loadingConfig && <Spinner color="white" />}
    </div>
  );
};

export default ConfigLoader;
