/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import DrEventsContext from "../../../../Reducers/DrEventsContext";

const topPaneStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const QuickFilter = () => {
  const [context] = useContext(DrEventsContext);

  return (
    <div css={topPaneStyles}>
      Filtering by:
      {context.drEventFilter.tenants &&
        context.drEventFilter.tenants.length > 0 &&
        context.drEventFilter.tenants.join(",")}
    </div>
  );
};

export default QuickFilter;
