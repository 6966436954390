/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import DrEventStatus from "./DrEventStatus";
import DrEventPopulation from "./DrEventPopulation";
import DrEventTime from "./DrEventTime";
import DrEventTenant from "./DrEventTenant";
import Colors from "../../../../Constants/Colors";

const eventNameStyle = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: ${Colors.SECONDARY_TEXT_COLOR_BRIGHT};
`;

const tenantFiltersStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: ${Colors.TEXT_COLOR_BRIGHT};
`;

const DrEventText = ({
  name,
  tenant,
  status,
  startTime,
  endTime,
  populationFilters,
}) => {
  return (
    <div>
      <DrEventStatus status={status} />
      <div css={eventNameStyle}>{name}</div>
      <div css={tenantFiltersStyle}>
        <DrEventTenant tenant={tenant} />
        {populationFilters && (
          <DrEventPopulation populationSelection={populationFilters} />
        )}
      </div>
      <DrEventTime startTime={startTime} endTime={endTime} />
    </div>
  );
};

DrEventText.propTypes = {
  name: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  populationFilters: PropTypes.object.isRequired,
};

export default DrEventText;
