import MuiFormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";

const Checkbox = ({
  color,
  disabledColor,
  checked,
  onChange,
  disabled,
  children,
}) => {
  return (
    <MuiFormControlLabel
      disabled={disabled}
      sx={{
        color: color,
        "& .MuiFormControlLabel-label.Mui-disabled": {
          color: color,
        },
      }}
      control={
        <MuiCheckbox
          checked={checked}
          onChange={onChange}
          sx={{
            color: color,
            "&.Mui-checked": {
              color: color,
            },
            "&.Mui-disabled": {
              color: disabledColor,
            },
            "&.Mui-disabled.Mui-checked": {
              color: disabledColor,
            },
          }}
        />
      }
      label={children}
    />
  );
};

Checkbox.defaultProps = {
  color: "default",
  disabledColor: "default",
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  color: PropTypes.string,
  disabledColor: PropTypes.string,
};

export default Checkbox;
