/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Colors from "../../../../Constants/Colors";

const timeStyle = css`
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: ${Colors.TEXT_COLOR_BRIGHT};
`;

const DrEventTime = ({ startTime, endTime }) => {
  const getTimeRange = (startTime, endTime) => {
    const utcStartTime = new Date(startTime);
    const utcEndTime = new Date(endTime);
    const timezone = "America/Denver";
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
      timeZone: timezone,
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      timeZone: timezone,
      hour: "numeric",
      minute: "2-digit",
    });

    const formattedstartTime = dateFormatter.format(utcStartTime);
    const formattedendTime = dateFormatter.format(utcEndTime);
    const formattedStartTime = timeFormatter.format(utcStartTime);
    const formattedEndTime = timeFormatter.format(utcEndTime);
    if (formattedendTime === formattedstartTime) {
      return `${formattedstartTime}, ${formattedStartTime} - ${formattedEndTime}`;
    } else {
      return `${formattedstartTime}, ${formattedStartTime} - ${formattedendTime}, ${formattedEndTime}`;
    }
  };

  return <div css={timeStyle}>{getTimeRange(startTime, endTime)}</div>;
};

DrEventTime.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
};

export default DrEventTime;
