/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Colors from "../../../../Constants/Colors";

const DrEventStatus = ({ status }) => {
  const getColorByStatus = (status) => {
    switch (status) {
      case "FAILED":
        return Colors.ERROR;
      default:
        return Colors.INFO;
    }
  };

  const eventStatusStyle = css`
    padding-top: 4px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    color: ${getColorByStatus(status)};
    padding-bottom: 15px;
  `;

  return <div css={eventStatusStyle}>{status}</div>;
};

DrEventStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default DrEventStatus;
