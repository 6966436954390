/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Bolt from "@mui/icons-material/Bolt";
import ReportIcon from "@mui/icons-material/Report";
import FlagIcon from "@mui/icons-material/Flag";
import DoneIcon from "@mui/icons-material/Done";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Colors from "../../../../Constants/Colors";

const DrEventStatusIcon = ({ status }) => {
  const defaultColor = Colors.INFO;
  const failedColor = Colors.ERROR;

  const getIconByStatus = (status) => {
    switch (status) {
      case "SCHEDULED":
        return <FlagIcon sx={{ color: defaultColor }}></FlagIcon>;
      case "PREPARING":
        return <Bolt sx={{ color: defaultColor }}></Bolt>;
      case "FAILED":
        return <ReportIcon sx={{ color: failedColor }}></ReportIcon>;
      case "COMPLETED":
        return <DoneIcon sx={{ color: defaultColor }}></DoneIcon>;
      case "RUNNING":
        return (
          <DirectionsRunIcon sx={{ color: defaultColor }}></DirectionsRunIcon>
        );
    }
  };

  return getIconByStatus(status);
};

DrEventStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default DrEventStatusIcon;
