import MuiList from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import Colors from "../../Constants/Colors";

const List = ({ items }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <MuiList>
      {items.map(({ key, imageIcon, text, link }) => (
        <ListItemButton
          onClick={() => navigate(link)}
          key={key}
          sx={{
            alignItems: "flex-start",
            background:
              location.pathname === link
                ? Colors.HIGHLIGHT_BRIGHT
                : Colors.PANE_BACKGROUND,
            marginTop: "2px",
            marginBottom: "2px",
            borderBottom: `1px solid ${Colors.BORDERS}`,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "30px",
            }}
          >
            {imageIcon}
          </ListItemIcon>
          <ListItemText
            sx={{
              marginTop: 0,
            }}
          >
            {text}
          </ListItemText>
        </ListItemButton>
      ))}
    </MuiList>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      imageIcon: PropTypes.element.isRequired,
      text: PropTypes.element.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
};

export default List;
