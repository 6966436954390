import callApi from "../Utilities/ApiUtilities";
import HttpMethods from "../Constants/HttpMethods";
import { ENDPOINTS } from "../Constants/Environment";
import { getAuth0Config } from "../Utilities/EnvironmentUtilities";

export default class DashboardService {
  constructor(getAccessToken) {
    this.getAccessToken = getAccessToken;
  }

  async getToken() {
    return await this.getAccessToken({
      authorizationParams: {
        audience: getAuth0Config().audience,
      },
    });
  }

  async getTenants() {
    const accessToken = await this.getToken();
    const response = await callApi(
      accessToken,
      ENDPOINTS.Tenants,
      HttpMethods.GET
    );
    return response;
  }

  async getDrEvents(filter) {
    const accessToken = await this.getToken();
    const response = await callApi(
      accessToken,
      ENDPOINTS.DrEvents,
      HttpMethods.POST,
      filter
    );
    return response;
  }
}
