import { withAuthenticationRequired } from "@auth0/auth0-react";
import PropTypes from "prop-types";

const AuthenticationGuard = ({ children }) => {
  const Component = withAuthenticationRequired(() => children, {
    onRedirecting: () => <div>Loading...</div>,
  });

  return <Component />;
};

AuthenticationGuard.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AuthenticationGuard;
