/** @jsxImportSource @emotion/react */
import { useReducer, useEffect } from "react";
import reducer, { initialState } from "../Reducers/DrEventsReducer";
import DrEventsContext from "../Reducers/DrEventsContext";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { css } from "@emotion/react";
import NavHeader from "./Navigation/NavHeader/NavHeader";
import NavSubHeader from "./Navigation/NavSubHeader/NavSubHeader";
import StaticLinks from "./Navigation/StaticLinks/StaticLinks";
import TenantFilter from "./DrEvents/Filters/TenantFilter/TenantFilter";
import PropTypes from "prop-types";
import Colors from "../Constants/Colors";
import ConfigLoader from "./ConfigLoader";
import { useAuth0 } from "@auth0/auth0-react";

const Layout = ({ navLinks }) => {
  const [context, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated || location.pathname !== "/") {
      return;
    }
    navigateToFirstRoute();
  }, [isAuthenticated]);

  const navigateToFirstRoute = () => {
    navigate(navLinks[0].link);
  };

  const containerStyle = css`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: stretch;
  `;

  const leftPaneStyles = css`
    display: flex;
    flex-direction: column;
    background: ${Colors.BACKGROUND_DARK};
    align-items: stretch;
    flex: 1;
  `;

  return (
    <div css={containerStyle}>
      <DrEventsContext.Provider value={[context, dispatch]}>
        <div css={leftPaneStyles}>
          <ConfigLoader />
          <NavHeader>Event Lifecycle</NavHeader>
          <NavSubHeader>View</NavSubHeader>
          <StaticLinks links={navLinks} />
          <TenantFilter />
        </div>
        <Outlet />
      </DrEventsContext.Provider>
    </div>
  );
};

Layout.propTypes = {
  navLinks: PropTypes.array.isRequired,
};

export default Layout;
