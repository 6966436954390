import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_ORGANIZATION,
  BACKEND_URLS,
} from "../Constants/Environment";

export const getAuth0Config = () => {
  let env = window.__appEnvironment.ENV;
  if (window.location.hostname == "localhost") {
    env = "local";
  }

  return {
    clientId: AUTH0_CLIENT_ID[env],
    domain: AUTH0_DOMAIN[env],
    organization: AUTH0_ORGANIZATION[env],
    audience: AUTH0_AUDIENCE[env],
  };
};

export const getBackendUrl = () => {
  let env = window.__appEnvironment.ENV;
  if (window.location.hostname == "localhost") {
    env = "local";
  }

  return BACKEND_URLS[env];
};
