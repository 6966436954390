/** @jsxImportSource @emotion/react */
import { Link, useLocation } from "react-router-dom";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Colors from "../../../Constants/Colors";

const StaticLinks = ({ links }) => {
  const location = useLocation();

  const dividerContainerStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: left;
  `;

  const linkButtonStyle = css`
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    padding: 8px;
    margin: 5px;
    border-radius: 4px;
    text-decoration: none;
  `;

  const linkTextStyle = css`
    padding-left: 5px;
  `;

  const linkButtonSelectedStyle = css`
    ${linkButtonStyle}
    background: ${Colors.HIGHTLIGHT_DARK};
  `;

  return (
    <div css={dividerContainerStyle}>
      {links.map(({ icon, link, label }) => (
        <Link
          key={link}
          to={link}
          css={
            location.pathname.startsWith(link)
              ? linkButtonSelectedStyle
              : linkButtonStyle
          }
        >
          {icon}
          <div css={linkTextStyle}>{label}</div>
        </Link>
      ))}
    </div>
  );
};

StaticLinks.propTypes = {
  links: PropTypes.array.isRequired,
};

export default StaticLinks;
