// TODO: Eventually all of the values in this file
// should be loaded via secret-manager via environment variables
// so that they wouldn't need to be manually updated if terraform
// recreated the auth0 resources.
const AUTH0_CLIENT_ID = Object.freeze({
  local: "CVTvGfuzKgMq7YX3qFBQkUdEVjp3TDFP",
  dev: "CVTvGfuzKgMq7YX3qFBQkUdEVjp3TDFP",
  stg: "Wmwx2cI3Ie93LPtDmlca8YKTBQBdqpXC",
  prd: "7yBbT0ycVdjiBsGrwIQuuJOoP1rTFK7m",
});

const AUTH0_DOMAIN = Object.freeze({
  local: "uplight-external-dev.us.auth0.com",
  dev: "uplight-external-dev.us.auth0.com",
  stg: "uplight-external-stg.us.auth0.com",
  prd: "uplight-external-prd.us.auth0.com",
});

const AUTH0_ORGANIZATION = Object.freeze({
  local: "org_o60ObF71TxmOmvTr",
  dev: "org_o60ObF71TxmOmvTr",
  stg: "org_VJNZpGSMA3Rd3NvV",
  prd: "org_tORBj3kesHI3lE3X",
});

const AUTH0_AUDIENCE = Object.freeze({
  local: "https://dr-dashboard-api-dev-rotw",
  dev: "https://dr-dashboard-api-dev-rotw",
  stg: "https://dr-dashboard-api-stg-rotw",
  prd: "https://dr-dashboard-api-prd-rotw",
});

const BACKEND_URLS = Object.freeze({
  local: "http://localhost:8000",
  dev: "https://dr-dashboard-api-dev-rotw-ae43-hrrhm3stzq-ue.a.run.app",
  stg: "https://dr-dashboard-api-stg-rotw-2b79-zrf762joya-ue.a.run.app",
  prd: "https://dr-dashboard-api-prd-rotw-1625-cfmebkameq-ue.a.run.app",
});

const ENDPOINTS = Object.freeze({
  Tenants: "/config/tenants",
  DrEvents: "/dr_events/filter",
});

export {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_ORGANIZATION,
  AUTH0_AUDIENCE,
  BACKEND_URLS,
  ENDPOINTS,
};
