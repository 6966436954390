import GenericSuccessCodes from "../Constants/GenericSuccessCodes";
import { getBackendUrl } from "./EnvironmentUtilities";
import mockFetch from "./MockApiResponses";

const handleFailedResponse = async (response) => {
  switch (response.status) {
    case 422:
    case 400: {
      const apiResponse = await response.json();
      return apiResponse;
    }
    case 401:
      return { exception: GenericSuccessCodes.Unauthorized };
    default:
      return { exception: GenericSuccessCodes.SystemError };
  }
};

const callApi = async (accessToken, endpoint, method, requestData) => {
  const properties = {
    method,
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
  };

  if (requestData) {
    properties.body = JSON.stringify(requestData);
    properties.headers.append("Content-Type", "application/json");
  }

  let response = null;
  if (process.env.REACT_APP_MOCK_API_CALLS == "true") {
    response = await mockFetch(`${getBackendUrl()}${endpoint}`, properties);
  } else {
    response = await fetch(`${getBackendUrl()}${endpoint}`, properties);
  }

  if (response.ok) {
    const apiResponse = await response.json();
    return apiResponse;
  }

  return handleFailedResponse(response);
};

export default callApi;
