/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import List from "../../../BasicComponents/List/List";
import PropTypes from "prop-types";
import DrEventStatusIcon from "./DrEventItem/DrEventStatusIcon";
import DrEventText from "./DrEventItem/DrEventText";
import Colors from "../../../Constants/Colors";

const listPaneStyles = css`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  background: white;
  border: 1px solid ${Colors.BORDERS};
  border-radius: 5px;
`;

const headerStyle = css`
  margin-left: 19px;
  margin-right: 19px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${Colors.BORDERS};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: ${Colors.TEXT_COLOR_BRIGHT};
`;

const noResultsStyle = css`
  margin-left: 19px;
  margin-right: 19px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  color: ${Colors.TEXT_COLOR_BRIGHT};
`;

const DrEventList = ({ drEvents }) => {
  const adaptItem = ({
    id,
    name,
    tenant,
    status,
    startTime,
    endTime,
    populationFilters,
  }) => {
    return {
      key: id,
      imageIcon: <DrEventStatusIcon status={status} />,
      text: (
        <DrEventText
          name={name}
          tenant={tenant}
          status={status}
          startTime={startTime}
          endTime={endTime}
          populationFilters={populationFilters}
        />
      ),
      link: `/drevents/${id}`,
    };
  };

  const adaptItems = (items) => {
    return items.map(adaptItem);
  };

  return (
    <div css={listPaneStyles}>
      <div css={headerStyle}>Events</div>
      {drEvents.length === 0 && (
        <div css={noResultsStyle}>No results found.</div>
      )}
      {drEvents.length > 0 && <List items={adaptItems(drEvents)} />}
    </div>
  );
};

DrEventList.propTypes = {
  drEvents: PropTypes.array.isRequired,
};

export default DrEventList;
