// BRIGHT refers to sections bright in color where the background is usually white.
// DARK refers to sections dark in color where the background is a dark color.

export default Object.freeze({
  INFO: "rgb(0, 121, 45)",
  WARNING: "rgb(240, 204, 1)",
  ERROR: "rgb(236, 2, 57)",

  BORDERS: "rgb(204, 204, 204)",
  HIGHLIGHT_BRIGHT: "rgb(232, 233, 253)",
  HIGHTLIGHT_DARK: "rgb(0, 9, 181)",

  BACKGROUND_BRIGHT: "linear-gradient(to bottom, #eeeeee, #ffffff)",
  BACKGROUND_DARK: "rgb(0, 18, 65)",
  PANE_BACKGROUND: "rgb(255, 255, 255)",

  HEADER_DARK: "rgb(255, 255, 255)",
  SUB_HEADER_DARK: "rgb(0, 226, 151)",

  TEXT_COLOR_BRIGHT: "rgb(34, 34, 34)",
  SECONDARY_TEXT_COLOR_BRIGHT: "rgb(87, 84, 108)",
  TEXT_COLOR_DARK: "rgb(255, 255, 255)",

  DISABLED_COLOR_DARK: "rgb(76, 89, 122)",
});
