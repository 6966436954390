/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { ACTIONS } from "../../../../Reducers/DrEventsReducer";
import DrEventsContext from "../../../../Reducers/DrEventsContext";
import CheckboxGroup from "../../../../BasicComponents/CheckboxGroup/CheckboxGroup";
import Divider from "../../../Navigation/Divider/Divider";
import NavSubHeader from "../../../Navigation/NavSubHeader/NavSubHeader";
import Colors from "../../../../Constants/Colors";

const tenantContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  padding-top: 10px;
`;

const TenantFilter = () => {
  const [context, dispatch] = useContext(DrEventsContext);
  const { drEventFilter, allConfiguredTenants: tenants } = context;

  const onAllTenantSelectionChange = (optionsSelected) => {
    dispatch({
      type: ACTIONS.UPDATED_TENANTS_FILTER,
      tenants: optionsSelected,
    });
  };

  const onTenantSelectionChange = (key, checked) => {
    const currentTenants = [...drEventFilter.tenants];
    if (checked) {
      currentTenants.push(key);
    } else {
      const indexToDelete = currentTenants.indexOf(key);
      if (indexToDelete !== -1) {
        currentTenants.splice(indexToDelete, 1);
      }
    }
    dispatch({
      type: ACTIONS.UPDATED_TENANTS_FILTER,
      tenants: currentTenants,
    });
  };

  return tenants && !context.loadingConfig ? (
    <>
      <Divider />
      <NavSubHeader>Tenants</NavSubHeader>
      <div css={tenantContainerStyle}>
        <CheckboxGroup
          options={tenants}
          allEnabled={true}
          color={Colors.TEXT_COLOR_DARK}
          disabledColor={Colors.DISABLED_COLOR_DARK}
          onAllChanged={onAllTenantSelectionChange}
          onCheckboxChange={onTenantSelectionChange}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

export default TenantFilter;
