/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Colors from "../../../Constants/Colors";

const NavHeader = ({ children }) => {
  const navHeaderStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: ${Colors.TEXT_COLOR_DARK};
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  `;

  return <div css={navHeaderStyle}>{children}</div>;
};

NavHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavHeader;
