import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";

const Spinner = ({ color, type }) => {
  switch (type) {
    case "circular":
      return (
        <CircularProgress
          sx={{
            color: color,
          }}
          data-testid="circular-spinner"
        />
      );
    default:
      return (
        <LinearProgress
          sx={{
            color: color,
            width: "100%",
          }}
          data-testid="linear-spinner"
        />
      );
  }
};

Spinner.defaultProps = {
  color: "default",
  type: "linear",
};

Spinner.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
};

export default Spinner;
