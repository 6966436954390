import { Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import NavigationLinkConfig from "./NavigationLinkConfig";
import AuthenticationGuard from "./Components/Auth/AuthenticationGuard";

const App = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticationGuard>
            <Layout navLinks={NavigationLinkConfig} />
          </AuthenticationGuard>
        }
      >
        {NavigationLinkConfig.map(({ link, container, children }) => (
          <Route key={link} path={link} element={container}>
            {children &&
              children.map(({ link, container }) => (
                <Route key={link} path={link} element={container} />
              ))}
          </Route>
        ))}
      </Route>
    </Routes>
  );
};

export default App;
