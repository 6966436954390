/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";
import Colors from "../../../Constants/Colors";

const drEventPaneStyles = css`
  display: flex;
  flex-direction: column;
  flex: 4;
  margin-right: 20px;
  margin-bottom: 20px;
  background: white;
  border: 1px solid ${Colors.BORDERS};
  border-radius: 5px;
  padding: 10px;
`;

const DrEventContainer = () => {
  const { drEventId } = useParams();
  return <div css={drEventPaneStyles}>Dr Event Container: {drEventId}</div>;
};

export default DrEventContainer;
