import { Auth0Provider } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { getAuth0Config } from "../../Utilities/EnvironmentUtilities";

const AuthProvider = ({ children }) => {
  const { domain, clientId, audience, organization } = getAuth0Config();

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
      organization={organization}
    >
      {children}
    </Auth0Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
