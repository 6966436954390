import PropTypes from "prop-types";

const DrEventPopulation = ({ populationSelection }) => {
  const getState = (populationSelection) => {
    return populationSelection.state ? `, ${populationSelection.state}` : "";
  };
  const getZones = (populationSelection) => {
    if (
      populationSelection.zones == null ||
      populationSelection.zones.length == 0
    ) {
      return "";
    }
    return `, ${populationSelection.zones.join(", ")}`;
  };

  return (
    <span>
      {getState(populationSelection)}
      {getZones(populationSelection)}
    </span>
  );
};

DrEventPopulation.propTypes = {
  populationSelection: PropTypes.object.isRequired,
};

export default DrEventPopulation;
