import DrEventListContainer from "./Components/DrEvents/DrEventsContainer";
import DrEventContainer from "./Components/DrEvents/DrEvent/DrEventContainer";
import CalendarContainer from "./Components/Calendar/CalendarContainer";
import OverviewContainer from "./Components/Overview/OverviewContainer";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EventIcon from "@mui/icons-material/Event";
import ShowChartIcon from "@mui/icons-material/ShowChart";

const NavigationLinkConfig = [
  {
    icon: <FormatListBulletedIcon />,
    link: "/drevents",
    label: "List",
    container: <DrEventListContainer />,
    children: [
      {
        link: "/drevents/:drEventId",
        container: <DrEventContainer />,
      },
    ],
  },
  {
    icon: <EventIcon />,
    link: "/calendar",
    label: "Calendar",
    container: <CalendarContainer />,
  },
  {
    icon: <ShowChartIcon />,
    link: "/overview",
    label: "Overview",
    container: <OverviewContainer />,
  },
];

export default NavigationLinkConfig;
