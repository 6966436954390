/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Outlet } from "react-router-dom";
import DrEventsContext from "../../Reducers/DrEventsContext";
import DashboardService from "../../Services/DashboardService";
import Spinner from "../../BasicComponents/Spinner/Spinner";
import DrEventList from "./DrEventList/DrEventList";
import Colors from "../../Constants/Colors";
import QuickFilter from "./Filters/QuickFilter/QuickFilter";
import { useAuth0 } from "@auth0/auth0-react";

const mainPaneStyles = css`
  display: flex;
  flex-direction: column;
  flex: 8;
  align-items: stretch;
  background: ${Colors.BACKGROUND_BRIGHT};
`;

const listAndItemPaneStyles = css`
  display: flex;
  flex-direction: row;
  flex: 10;
  align-items: stretch;
  flex-wrap: wrap;
`;

const listPaneStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const spinnerContainerStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const DrEventsContainer = () => {
  const [context] = useContext(DrEventsContext);
  const [drEvents, setDrEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loadingDrEvents, setLoadingDrEvents] = useState(true);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        return;
      }

      setLoadingDrEvents(true);
      await loadDrEvents();
      setLoadingDrEvents(false);
    })();
  }, [isAuthenticated, context.drEventFilter]);

  const isLoading = () => {
    return context.loadingConfig || loadingDrEvents;
  };

  const loadDrEvents = async () => {
    try {
      const dashboardService = new DashboardService(getAccessTokenSilently);
      const drEvents = await dashboardService.getDrEvents(
        context.drEventFilter
      );
      setDrEvents(drEvents);
    } catch (exception) {
      console.log(exception);
      setError("Failed to retrieve dr events!");
    }
  };

  return (
    <div css={mainPaneStyles}>
      <QuickFilter />
      <div css={listAndItemPaneStyles}>
        <div css={listPaneStyles}>
          {error && <div>Error: {error}</div>}
          {isLoading() && (
            <div css={spinnerContainerStyle}>
              <Spinner type="circular" color="black" />
            </div>
          )}
          {!isLoading() && drEvents && <DrEventList drEvents={drEvents} />}
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default DrEventsContainer;
