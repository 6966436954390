import { ENDPOINTS } from "../Constants/Environment";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const mockSuccessResponse = async (data, delayMs) => {
  await sleep(delayMs);
  return Promise.resolve({
    ok: true,
    json: () => data,
  });
};

const mockFetch = async (url) => {
  console.log("Calling the mocked fetch function");
  switch (url) {
    case `${process.env.REACT_APP_API_URL}${ENDPOINTS.Tenants}`: {
      const tenants = [
        { key: "el_paso", label: "El Paso Electric" },
        { key: "ameren_mo", label: "Ameren" },
        { key: "pg_e", label: "Pacific Gas & Electric" },
        { key: "smud", label: "Smud" },
      ];
      return await mockSuccessResponse(tenants, 3000);
    }
    case `${process.env.REACT_APP_API_URL}${ENDPOINTS.DrEvents}`: {
      const drEvents = [
        {
          id: "12345",
          name: "Event 1",
          tenant: "el_paso",
          status: "SCHEDULED",
          startTime: "2023-06-23T18:00:00.000Z",
          endTime: "2023-06-23T20:00:00.000Z",
          populationFilters: {
            state: "NM",
            zones: ["PGCC", "PGNB"],
          },
        },
        {
          id: "44444",
          name: "Event 2",
          tenant: "smud",
          status: "PREPARING",
          startTime: "2023-06-25T16:00:00.000Z",
          endTime: "2023-06-23T18:00:00.000Z",
          populationFilters: {},
        },
        {
          id: "555555",
          name: "Event 3",
          tenant: "ameren_mo",
          status: "FAILED",
          startTime: "2023-06-24T16:00:00.000Z",
          endTime: "2023-06-24T18:00:00.000Z",
          populationFilters: {},
        },
        {
          id: "101019",
          name: "05.24.23 PGCC, PGNB, PGP2, PGSB, PGSF",
          tenant: "pg_e",
          status: "COMPLETED",
          startTime: "2023-05-24T16:00:00.000Z",
          endTime: "2023-05-24T18:00:00.000Z",
          populationFilters: {
            zones: ["PGCC", "PGNB", "PGP2", "PGSB", "PGSF"],
          },
        },
        {
          id: "9898989",
          name: "06.24.23 PGCC, PGNB, PGP2, PGSB",
          tenant: "pg_e",
          status: "RUNNING",
          startTime: "2023-06-24T16:00:00.000Z",
          endTime: "2023-06-24T18:00:00.000Z",
          populationFilters: {
            zones: ["PGCC", "PGNB", "PGP2", "PGSB"],
          },
        },
      ];
      return await mockSuccessResponse(drEvents, 2000);
    }
    default:
      return Promise.reject(new Error("Mock not found for this URL"));
  }
};

export default mockFetch;
