/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const dividerContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
`;

const dividerStyle = css`
  width: 100%;
  margin: 0;
  height: 1px;
  border-width: 0;
  color: #4c597a;
  background-color: #4c597a;
`;

const Divider = () => {
  return (
    <div css={dividerContainerStyle}>
      <hr css={dividerStyle} />
    </div>
  );
};

export default Divider;
