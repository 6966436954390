export const initialState = {
  drEventFilter: {
    tenants: [],
    startDateRange: null,
    endDateRange: null,
    status: null,
  },
  allConfiguredTenants: [],
  loadingConfig: true,
};

export const ACTIONS = Object.freeze({
  UPDATED_TENANTS_FILTER: "UPDATED_TENANTS_FILTER",
  LOADED_TENANTS: "LOADED_TENANTS",
});

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATED_TENANTS_FILTER:
      return {
        ...state,
        drEventFilter: {
          ...state.drEventFilter,
          tenants: [...action.tenants],
        },
        loading: true,
      };
    case ACTIONS.LOADED_TENANTS:
      return {
        ...state,
        allConfiguredTenants: [...action.allConfiguredTenants],
        loadingConfig: false,
      };
    default:
      throw new Error("Reducer action type not handled.");
  }
};

export default reducer;
