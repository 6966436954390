/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Colors from "../../Constants/Colors";

const mainPaneStyles = css`
  display: flex;
  flex-direction: column;
  flex: 8;
  align-items: stretch;
  background: ${Colors.BACKGROUND_BRIGHT};
`;

const OverviewContainer = () => {
  return <div css={mainPaneStyles}>Overview Container</div>;
};

export default OverviewContainer;
