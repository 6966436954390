import PropTypes from "prop-types";
import { useContext } from "react";
import DrEventsContext from "../../../../Reducers/DrEventsContext";

const DrEventTenant = ({ tenant }) => {
  const [context] = useContext(DrEventsContext);

  const getTenantLabel = (tenantKey) => {
    const tenantObject = context.allConfiguredTenants.find(
      (tenant) => tenant.key === tenantKey
    );
    return tenantObject ? tenantObject.label : tenantKey;
  };

  return <span>{getTenantLabel(tenant)}</span>;
};

DrEventTenant.propTypes = {
  tenant: PropTypes.string.isRequired,
};

export default DrEventTenant;
